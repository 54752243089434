import '../assets/css/profileedit.css';

import Formsy from 'formsy-react';
import { navigate, Link } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';

import Layout from '../components/layout';
import closeChat from '../images/close-icon.svg';
import MyInput from '../components/MyInput';
import MyInputMask from '../components/MyInputMask';
import SEO from '../components/seo';
import { setCredentials } from './../core/auth';
import { isAuthenticated,getAuthenticatedUser } from '../core/util';
import * as actionCreators from './../store/actions';
class CardEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            userToken:null,
            profile:null,
            successMsg:null,
            contractId:null
        };
    }

    async componentDidMount() {
        if (!isAuthenticated()) {
            navigate("/"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
        if(this.props.location.state && this.props.location.state.contractId){
            this.setState({ contractId: this.props.location.state.contractId })
        } else {
           navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }

        let usrdata = getAuthenticatedUser();
        this.setState({ userToken: usrdata.token })
        await this.getFromDirect(usrdata.token);
    }

    getFromDirect = async(paramId) => {
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getProfile'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: paramId,
                })
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    this.setState({ profile: res.me })
                } else {
                    setCredentials({});
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
            });
    }
    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = async(model) => {
        const { showLoader, hideLoader } = this.props;
        showLoader();
        model.token = this.state.userToken;
        model.contractId = this.state.contractId;
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/editCard'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(model)
            })
        .then(res => res.json())
        .then((res) => {
            if(res.status === 1){
                this.setState({ successMsg: 'Your Card Updated Successfully' })
            } else if(res.status === 0){
                    this.setState({ successMsg: 'Your Card Updated Failed' })
            } else if(res.status === -1) {
                setCredentials({});
                navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
            }
            hideLoader();
        });

    }

    render() {
        const { canSubmit, profile,successMsg } = this.state;
        if(!profile) return '';
        return (
            <Layout layoutType="profileHeader">
                <SEO title="Servicecontract.com - Contact" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                {/* --------------banner--------- */}

                <div className={global.dealertoken?"profilecontainer grayish":"profilecontainer"}>
                    <div className="container">
                        <div className="profile-content">
                          {/*  <LeftProfile />*/}


                            <div className="right-side">
                                <Formsy ref="profileForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                    <div className="form">
                                        <div className="updateaccount-container">
                                            <h1>Update Card Info <img onClick={() => navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))} className="userclose" src={closeChat} alt="" /></h1>
                                            <span className="edit-info">{successMsg}</span>
                                        </div>
                                        <div className="form-inline">
                                            <div className="left">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="cardFirstName"
                                                        id="cardFirstName"
                                                        placeholder="First Name"
                                                        className="form-control"
                                                        value=""
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="cardLastName"
                                                        id="cardLastName"
                                                        placeholder="Last Name"
                                                        className="form-control"
                                                        value=""
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-inline">
                                            <div className="left">
                                                <div className="form-label-group error form-part">
                                                    <MyInputMask
                                                        name="cardNo"
                                                        id="cardNo"
                                                        mask="9999 9999 9999 9999"
                                                        placeholder="Credit Card Number"
                                                        validations={{
                                                            maxLength: 19,
                                                            minLength: 16
                                                        }}
                                                        validationError="Please enter a valid card number."
                                                        className="form-control"
                                                        value=""
                                                        isLabelRequired={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="form-label-group error form-part">
                                                    <MyInputMask
                                                        name="cvv"
                                                        id="cvv"
                                                        mask="999"
                                                        placeholder="CVV"
                                                        validations={{
                                                            maxLength: 3,
                                                            minLength: 3
                                                        }}
                                                        validationError="Please enter a valid CVV."
                                                        isLabelRequired={true}
                                                        className="form-control"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-inline">
                                            <div className="left">
                                                <div className="form-label-group error form-part">
                                                    <MyInputMask
                                                        name="expMMYY"
                                                        id="expMMYY"
                                                        mask="99/99"
                                                        placeholder="Exp Date (MM/YY)"
                                                        validationError="Please enter a valid MM/YY."
                                                        className="form-control"
                                                        value=""
                                                        isLabelRequired={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="button-container change-pass-container-btn">
                                        <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled change-pass-btn' : 'btn change-pass-btn'}>
                                            UPDATE
                                        </button>
                                        <Link className="btn change-pass-btn outline" to={"/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:'')}>Back</Link>
                                    </div>
                                </Formsy>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="copyright-container last-text">
                    <div className="container">
                        <div className="copyright">
                            <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
                        </div>
                    </div>

                </section>

            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        showSuccessPopup: (payload) => dispatch(actionCreators.showSuccessPopup(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CardEditPage);

